import { Box, Button, Dialog, DialogActions, DialogContent, LinearProgress } from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { greyboxApiActions } from '../../../redux/api';
import DialogTitle from '../../DialogTitle';
import CalendarView from './CalendarView';
import EventForm from './EventForm';
import { LoadingButton } from '@mui/lab';
import { getInitialValues, getValidationSchema, handleSubmit } from './utils';

const AddEventModal = ({ open, handleClose, refetchEvents, selectedType, date, eventId, isAllDay }: AddEventModalProps) => {
  const { t } = useTranslation();
  const { calendar, calendarEvent } = greyboxApiActions;
  const { uuid } = useParams();
  const { clinic } = useSelector((state) => state.clinic);
  const [showRecurrence, setShowRecurrence] = useState(false);
  const calendarSelector = calendar.list({ owner: uuid, clinic: clinic.id });
  const calendarRef = useRef(null);
  const [deleteEvent] = calendarEvent.delete();
  const [updateEvent] = calendarEvent.update();
  const [addEvent] = calendar.add();

  let { data, isLoading } = calendarEvent.get(eventId, { skip: !eventId });

  useEffect(() => {
    if (eventId && data) {
      formik.setValues(getInitialValues(data, date, selectedType, isAllDay));
    }
  }, [eventId, data]);

  const formik = useFormik({
    initialValues: getInitialValues(data, date, selectedType, isAllDay),
    validationSchema: getValidationSchema(t),
    onSubmit: async (values) => {
      await handleSubmit(values, calendarSelector, clinic, eventId, refetchEvents, addEvent, updateEvent);
      handleClose();
    },
  });

  useEffect(() => {
    if (formik.values.start_datetime && calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      const isoFormattedDate = formik.values.start_datetime.toISOString(); // Convert to ISO 8601 format
      calendarApi.gotoDate(isoFormattedDate);
    }
  }, [formik.values.start_datetime, calendarRef.current]);

  if (isLoading || calendarSelector.isLoading) {
    return (
      <Dialog open={open} fullWidth maxWidth="sm">
        <LinearProgress />
        <DialogContent sx={{ height: '300px' }} />
      </Dialog>
    );
  }

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
      <DialogTitle onClose={handleClose}>
        {`${eventId ? t('Edit event') : t('Add event')} ${selectedType.id !== 'Event' ? `(${t(formik.values.resourcetype)})` : ''}`}
      </DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent dividers sx={{ p: 0 }}>
          <Box display="flex">
            <EventForm
              formik={formik}
              showRecurrence={showRecurrence}
              setShowRecurrence={setShowRecurrence}
            />
            <CalendarView calendarRef={calendarRef} date={date} calendarSelector={calendarSelector} />
          </Box>
        </DialogContent>
        <DialogActions>
          {eventId && (
            <Button
              id="delete"
              onClick={() => {
                deleteEvent(eventId).then(refetchEvents);
                handleClose();
              }}
              color="error"
            >
              {t('Delete')}
            </Button>
          )}
          <Button onClick={handleClose}>{t('Cancel')}</Button>
          <LoadingButton id="submit" type="submit" loading={formik.isSubmitting}>
            {t('Save')}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddEventModal;
